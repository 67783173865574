import React, {useEffect, useState} from "react";
import {Button} from "components/Button";

import styles from "./styles.module.scss";

const NotFound = () => {
  const [chatLoaded, setChatLoaded] = useState(false);

  useEffect(() => {
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = '//code.jivo.ru/widget/E4SJpIwsNu';
    script.onload = () => setChatLoaded(true);
    head.appendChild(script);
  }, []);

  const openChat = () => {
    if (chatLoaded) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.jivo_api.open();
    }
  };

  return (
    <div className={styles.notFound}>
      <div className={styles.header}><i/></div>
      <p>Просканируйте QR-код на карте, чтобы получить доступ к вашей сессии</p>
      <div className={styles.bottom}>
        <Button onClick={() => window.open("https://t.me/GetValetClientBot", "_self")}>Техническая поддержка</Button>
        <Button onClick={openChat}>Сообщить о проблеме</Button>
        <Button onClick={() => window.open("https://getvalet.ru/company/docs/oferta.docx", "_self")}>Договор офферты</Button>
      </div>
    </div>
  );
};

export default NotFound;

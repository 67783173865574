import React, {
  memo, useEffect, useMemo
}                                   from 'react';
import getQueryParams               from "utils/getQueryParams";
import {useSelector}                from "react-redux";
import {useAppDispatch}             from "store";
import {getParkingInfo}             from "store/parking/thunk";
import {Top}                        from 'components/Top';
import {STATUS}                     from "store/consts";
import {Loader}                     from "components/Loader";
import {Button}                     from "components/Button";
import {Order}                      from 'pages/home/views/components/Order';
import {HeaderName}                 from "pages/home/views/components/HeaderName";
import {LOG_TYPES}                  from "utils/consts";
import {log}                        from "utils/analytics";
import NotFound                     from "./NotFound";

import styles                       from "./styles.module.scss";

import type {RootState}             from "store";


function Home() {
  const {place, id} = useMemo(() => getQueryParams(), []);

  const {parkingInfo , infoLoading: {status, error}} = useSelector((s: RootState) => s.parking);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (place && id && !parkingInfo) {
      dispatch(getParkingInfo({place, id}));
    }
  }, [id, place, parkingInfo]);

  useEffect(() => {
    log(LOG_TYPES.HOME_PAGE_OPENED, parkingInfo);
  }, []);

  let content;

  if (status === STATUS.LOADING && !parkingInfo) {
    content = <Loader />;
  }

  if (status === STATUS.FULFILLED) {
    content = (
      <>
        <Top />
        <div className={styles.container}>
          <HeaderName title={parkingInfo?.parking.name} description={parkingInfo?.parking.address} />
          <Order />
        </div>
      </>
    );
  }

  if (status === STATUS.REJECTED && error !== 400) {
    content = (
      <div className={styles.error}>
        <p>Ошибка во время загрузке данных.</p>
        <div>
          <Button onClick={() => window.location.reload()}>Попробовать снова</Button>
        </div>
      </div>
    );
  }

  if (error === 400) {
    content = (
      <div className={styles.error}>
        <p>Валет-сессия по данной карте не найдена.</p>
      </div>
    );
  }

  if (!place || !id) {
    content = <NotFound />;
  }

  return (
    <>{content}</>
  );
}

export default memo(Home);
